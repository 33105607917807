import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/jasmeet.en.yaml'
import contentId from '../../../content/pages/products/jasmeet.id.yaml'
import OtherProducts from '../../components/other-products'
import SEO from '../../components/seo'

export const query = graphql`
  query JasmeetQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(relativePath: { eq: "images/jasmeet/jasmeet-logo.svg" }) {
      id
      publicURL
    }
    laptopFigure: file(relativePath: { eq: "images/jasmeet/jasmeet-figure-laptop.png" }) {
      id
      publicURL
    }
    sectionTwoBackground: file(relativePath: { eq: "images/jasmeet/jasmeet-section-two-background-element.svg" }) {
      id
      publicURL
    }
    browserIcon: file(relativePath: { eq: "images/jasmeet/jasmeet-icon-browser.png" }) {
      id
      publicURL
    }
    integrationIcon: file(relativePath: { eq: "images/jasmeet/jasmeet-icon-integration.png" }) {
      id
      publicURL
    }
    roadSignIcon: file(relativePath: { eq: "images/jasmeet/jasmeet-icon-road-sign.png" }) {
      id
      publicURL
    }
    shieldIcon: file(relativePath: { eq: "images/jasmeet/jasmeet-icon-shield.png" }) {
      id
      publicURL
    }
    telephoneIcon: file(relativePath: { eq: "images/jasmeet/jasmeet-icon-telephone.png" }) {
      id
      publicURL
    }
  }
`

interface JasmeetPagePropsType {
  data: {
    logo: PublicUrlType
    laptopFigure: PublicUrlType
    sectionTwoBackground: PublicUrlType
    browserIcon: PublicUrlType
    integrationIcon: PublicUrlType
    roadSignIcon: PublicUrlType
    shieldIcon: PublicUrlType
    telephoneIcon: PublicUrlType
  }
}

const JasmeetPage = (props: JasmeetPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    logo,
    laptopFigure,
    sectionTwoBackground,
    browserIcon,
    integrationIcon,
    roadSignIcon,
    shieldIcon,
    telephoneIcon,
  } = props.data

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-jasmeet-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <div
        className="uk-background-norepeat uk-background-bottom-right"
        data-src={sectionTwoBackground.publicURL}
        uk-img=""
      >
        <section className="uk-section uk-section-large">
          <div className="uk-with-1-1">
            <div className="uk-container">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  <h2 className="uk-width-1-2@m">{content.solution.title}</h2>
                  <div uk-grid="">
                    <div>
                      <ul className="uk-list uk-list-disc">
                        {content.solution.description.slice(0, 3).map((list: string, index: number) => (
                          <li key={list}>{list}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <ul className="uk-list uk-list-disc">
                        {content.solution.description.slice(3, 5).map((list: string, index: number) => (
                          <li key={list}>{list}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <img alt="jasmeet interface" uk-img={laptopFigure.publicURL} />
                </div>
              </div>

              <div
                className="uk-margin-large-top"
                uk-grid=""
                uk-height-match="target: > div > .uk-card"
                // uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
              >
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.chat.title}</h3>
                    <p>{content.solution.chat.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.web_camera.title}</h3>
                    <p>{content.solution.web_camera.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.audio.title}</h3>
                    <p>{content.solution.audio.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.emoticon.title}</h3>
                    <p>{content.solution.emoticon.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.breakout_room.title}</h3>
                    <p>{content.solution.breakout_room.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.poll.title}</h3>
                    <p>{content.solution.poll.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.screen_sharing.title}</h3>
                    <p>{content.solution.screen_sharing.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-4@m">
                  <div className="uk-card uk-card-default uk-card-body">
                    <h3 className="uk-card-title uk-text-capitalize">{content.solution.multi_user_whiteboard.title}</h3>
                    <p>{content.solution.multi_user_whiteboard.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="uk-section uk-section-large">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-1-2@m uk-text-center uk-margin-auto uk-margin-large-bottom">
              <div className="uk-margin">
                <h2>{content.best_features.title}</h2>
              </div>
            </div>
            <div>
              <div
                className="uk-child-width-1-5@m"
                uk-grid=""
                uk-height-match="target: > div > .uk-card"
                uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
              >
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="privacy" uk-img={shieldIcon.publicURL} />
                    <p>{content.best_features.security}</p>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="100 uptime" uk-img={roadSignIcon.publicURL} />
                    <p>{content.best_features.sla}</p>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="easy integration" uk-img={integrationIcon.publicURL} />
                    <p>{content.best_features.integration}</p>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="voip" uk-img={telephoneIcon.publicURL} />
                    <p>{content.best_features.with_jascloud}</p>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="easy customization" uk-img={browserIcon.publicURL} />
                    <p>{content.best_features.customization}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BottomCta color="#f5821f" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default JasmeetPage
